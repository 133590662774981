<template>
  <div class="page-manual-edit">
    <div class="container">
      <form @submit.prevent='onSubmit()'>
        <h1>{{ $t("manual.title") }}</h1>
        <fieldset>
          <div class="item">
            <label for="l">{{ $t('manual.nr_cat') }}</label>
            <input id="l" type='text' required v-model='nr_cat'/>
          </div>

          <div class="item">
            <label for="p">{{ $t('manual.qualifier') }}</label>
            <input id="p" type='text' required v-model='qualification_id' />
          </div>

          <div class="item">
            <label for="p">{{ $t('manual.rank') }}</label>
            <input id="p" type='text' required v-model='classement' />
          </div>

          <div class="item">
            <label for="p">{{ $t('manual.reward') }}</label>
            <input id="p" type='text' required v-model='recompense' />
          </div>
          <div class="item">
            <label for="p">{{ $t('manual.judge') }}</label>
            <input id="p" type='text' required v-model='juge' />
          </div>

          <div class="item">
            <label for="p">{{ $t('manual.comment') }}</label>
            <textarea v-model="comment" :placeholder="$t('manual.comment_placeholder')"></textarea>
          </div>

        </fieldset>
        <div class="actions">
          <button class="action cancel" @click="onReset">{{ $t('global.reset') }}</button>
          <button class="action submit" :disabled="!isFilled"
            @click="onSubmit">{{ $t('global.submit') }}</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import dogMixin from '../common/dog.mixin';

export default {
  components: { },
  mixins: [dogMixin],
  data() {
    return {
      nr_cat: null,
      qualification_id: null,
      classement: null,
      recompense: null,
      juge: null,
      comment: null,
    };
  },
  name: 'rewards',
  computed: {
    ...mapGetters([
      'getAllDogs', 'getClassById',
    ]),
    isFilled() {
      return this.nr_cat && this.qualification_id
        && this.classement && this.recompense && this.juge;
    },
  },
  mounted() {
  },
  methods: {
    onReset() {
      this.nr_cat = null;
      this.juge = null;
      this.qualification_id = null;
      this.classement = null;
      this.recompense = null;
      this.comment = null;
    },
    onSubmit() {
      console.log('ok');
    },
  },
};
</script>

<style lang="scss">
.page-manual-edit {
  fieldset {border:0}
  label {font-weight: 500; color: #494949}
  input { width: 100%; padding: .5rem;
    margin-bottom: 1rem; border: 0; background-color: white;
    border-bottom: 1px #AAA solid; box-shadow:none;
  }
  textarea { width: 100%; padding: .5rem ; background-color: white}
  .error-messages { color: #b85c5c !important; font-weight: 700; margin-bottom: 1rem;
    text-align: center}

  .actions {display: flex; justify-content: flex-end; gap: 1rem}
}
</style>
